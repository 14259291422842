<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="산업보건 일지 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 저장 -->
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="journal"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable" 
                  :disabled="disabled"
                  type="edit" 
                  name="plantCd" 
                  v-model="journal.plantCd"/>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="작성일"
                  type="date"
                  name="startDate"
                  v-model="journal.startDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="일지명"
                  name="journalName"
                  v-model="journal.journalName">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="totalGrid"
            title="전체 지급 현황"
            :columns="totalGrid.columns"
            :gridHeight="totalGrid.height"
            :data="totalGrid.data"
            :gridHeightAuto="true"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            :editable="editable"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row[col.name] > 0">
                {{ props.row[col.name] }}
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="grid"
            title="의약품&의약외품 지급 현황"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="journal.results"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="industryHealthJournalItemId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 추가 -->
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addResult"
                />
                <!-- 제외 -->
                <c-btn
                  v-if="editable && !disabled && journal.results.length > 0"
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeResult"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="grid2"
            title="물리치료&외상처리 현황"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="journal.statuses"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="industryHealthJournalStatusId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 추가 -->
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addResult2"
                />
                <!-- 제외 -->
                <c-btn
                  v-if="editable && !disabled && journal.statuses.length > 0"
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeResult2"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'industry-health-journal-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        industryHealthJournalId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      totalGrid: {
        columns: [
          {
            name: 'code1',
            field: 'code1',
            label: '소화기',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code2',
            field: 'code2',
            label: '호흡기',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code3',
            field: 'code3',
            label: '근골',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code4',
            field: 'code4',
            label: '신경',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code5',
            field: 'code5',
            label: '외상',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code6',
            field: 'code6',
            label: '화상',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code7',
            field: 'code7',
            label: '안구',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code8',
            field: 'code8',
            label: '물리치료',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code9',
            field: 'code9',
            label: '기타',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
          },
          {
            name: 'total',
            field: 'total',
            label: '합계',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid: {
        columns: [
        ],
        height: "360px",
        data: [],
      },
      grid2: {
        columns: [
        ],
        height: "360px",
        data: [],
      },
      journal: {
        industryHealthJournalId: '',
        plantCd: '',
        startDate: '',
        endDate: '',
        journalName: '',
        completeFlag: 'N',
        results: [],
        deleteResults: [],
        statuses: [],
        deleteStatuses: [],
      },
      editable: true,
      saveType: 'POST',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isApproval: false,
      updateUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 500;
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.industryHealthJournalId)
    },
    disabled() {
      return this.journal.completeFlag === 'Y' || this.journal.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.ihj.get.url;
      this.saveUrl = transactionConfig.hea.ihj.insert.url;
      this.updateUrl = transactionConfig.hea.ihj.update.url;
      this.deleteUrl = transactionConfig.hea.ihj.delete.url;
      // code setting
      // list setting
      this.setHeader();
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.totalGrid.data = [];
        this.$http.url = this.$format(this.detailUrl, this.popupParam.industryHealthJournalId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.journal = _result.data;
          
          this.totalGrid.data.push(_result.data) 

          this.$emit('setRegInfo', _result.data)
        },);
      } 
    },
    setHeader() {
      this.$comm.getComboItems('JOURNAL_SYMPTOM_CD').then(_result => {
        let firstItems = this.$_.filter(_result, {attrVal1: 'Y'});
        let secondItems = this.$_.filter(_result, {attrVal1: 'N'});
        this.grid.columns = [
          {
            name: 'journalDeptName',
            field: 'journalDeptName',
            label: '부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'journalUserName',
            field: 'journalUserName',
            label: '이름',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'journalSymptomCd',
            field: 'journalSymptomCd',
            label: '증상',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: firstItems,
            itemText: 'codeName',
            itemValue: 'code',
            sortable: false,
          },
          {
            required: true,
            name: 'journalTypeName',
            field: 'journalTypeName',
            label: '구분',
            type: 'text',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'journalSymptomAction',
            field: 'journalSymptomAction',
            label: '처치사항',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
        ];
        this.grid2.columns = [
        {
            name: 'journalDeptName',
            field: 'journalDeptName',
            label: '부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'journalUserName',
            field: 'journalUserName',
            label: '이름',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'journalSymptomCd',
            field: 'journalSymptomCd',
            label: '증상',
            align: 'center',
            type: 'select',
            comboItems: secondItems,
            itemText: 'codeName',
            itemValue: 'code',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'journalTypeName',
            field: 'journalTypeName',
            label: '구분',
            type: 'text',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'workFlag',
            field: 'workFlag',
            label: '업무연관성 유무',
            type: 'check',
            true: 'Y',
            false: 'N',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'journalSymptomDetail',
            field: 'journalSymptomDetail',
            label: '처치사항',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'journalSymptomAction',
            field: 'journalSymptomAction',
            label: '사유',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
        ]
      });
      this.grid.col
    },
    addResult() {
      this.popupOptions.title = "사용자 검색";
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.journal.results, {
            industryHealthJournalItemId: _item.industryHealthJournalItemId,
          });
          if (index === -1) {
            this.journal.results.splice(0, 0, {
              industryHealthJournalId: this.popupParam.industryHealthJournalId,
              industryHealthJournalItemId: uid(),
              journalDate: this.$comm.getToday(),
              journalDeptName: _item.deptName,
              journalUserId: _item.userId,
              journalUserName: _item.userName,
              journalSymptomCd: null,
              journalTypeName: '',
              journalSymptomDetail: '',
              journalSymptomAction: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.journal.deleteResults) this.journal.deleteResults = [];
          if (this.$_.findIndex(this.journal.deleteResults, {
            industryHealthJournalItemId: item.industryHealthJournalItemId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.journal.deleteResults.push(item);
          }
          this.journal.results = this.$_.reject(this.journal.results, item);
        });
      }
    },
    addResult2() {
      this.popupOptions.title = "사용자 검색";
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup2;
    },
    closeUserPopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.journal.statuses, {
            industryHealthJournalStatusId: _item.industryHealthJournalStatusId,
          });
          if (index === -1) {
            this.journal.statuses.splice(0, 0, {
              industryHealthJournalId: this.popupParam.industryHealthJournalId,
              industryHealthJournalStatusId: uid(),
              journalDate: this.$comm.getToday(),
              journalDeptName: _item.deptName,
              journalUserId: _item.userId,
              journalUserName: _item.userName,
              journalTypeName: _item.journalTypeName,
              journalSymptomCd: null,
              journalSymptomDetail: '',
              journalSymptomAction: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeResult2() {
      let selectData = this.$refs["grid2"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.journal.deleteStatuses) this.journal.deleteStatuses = [];
          if (this.$_.findIndex(this.journal.deleteStatuses, {
            industryHealthJournalStatusId: item.industryHealthJournalStatusId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.journal.deleteStatuses.push(item);
          }
          this.journal.statuses = this.$_.reject(this.journal.statuses, item);
        });
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.hea.ihj.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.ihj.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.journal.results)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.journal.regUserId = this.$store.getters.user.userId
                this.journal.chgUserId = this.$store.getters.user.userId
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'industryHealthJournalId', _result.data)
      }
      this.getDetail();
    },
  }
};
</script>
